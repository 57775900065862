.colTimeStatus
  width: 10px
  height: 55px

.textSmaller
  font-size: 80%

.colorGrey
  color: grey
.colorDarkGreen
  color: darkgreen
.colorDarkRed
  color: darkred

.tagsColumn
  position: relative
  .tagsCategory
    margin-left: 10px
  .tagLink
    border: solid 1px transparent
    color: white
    display: inline-block
    margin: 5px
    padding: 4px
    border-radius: 4px
    &:hover
      opacity: 0.8
      text-decoration: none
      border: solid 1px lightgrey
      cursor: pointer
  .tagsComments
    position: absolute
    right: 5px
    top: 5px

.columnDate
  margin-bottom: 5px
  font-size: 12px

.colUsers
  font-size: 8px
  vertical-align: -1px