.updating
  padding: 20px
  text-align: center

.categoriesInfoRow
  &:hover
    cursor: pointer
    background-color: #d3d3d3 !important
  .categoriesInfo
    margin-top: 10px
    border-top: solid 1px grey
    padding-top: 10px
    padding-bottom: 5px
  .categoriesList
    display: flex
    .categoryLinkContainer
      margin-left: 10px
      .categoryLink
        background-color: darkred
        color: white
        padding: 5px
        &:hover
          cursor: pointer
          background-color: #5b1313
    .categoryCount
      width: 50px
