.subtasks, .newSubtasks

  .subtask
    padding-top: 5px
    padding-bottom: 5px
    border-bottom:  dashed 1px grey
    margin-bottom: 3px
    margin-top: 3px

    &:hover
      .contentDiv
        background-color: #f0f0f0
    .checkDiv
      float: left
      padding-top: 2px
      .ms-Icon--CheckboxComposite
        color: darkgreen
      .ms-Icon--Checkbox
        color: darkred
      &:hover
        cursor: pointer
        font-weight: bold
    .contentDiv
      padding-left: 3px
      padding-right: 3px
      float: right
      width: calc(100% - 20px)
      small
        line-height: 1.4
        display: block
        margin-top: 5px
        font-size: 13px
        color: grey
        .pointer
          cursor: pointer
        span
          &:first-child
            display: inline-block
            /* width: 100px */
          u
            color: black
    .topic
      font-size: 16px
      a
        visibility: hidden
        float: right
        margin-left: 6px
        margin-right: 5px
        font-size: 18px
        &.delete
          color: darkred

    &:hover
      .topic
        a
          visibility: visible
          &:hover
            cursor: pointer

    .wFieldPresentationWysiwyg
      margin-top: 5px
      background-color: #fffacd
      padding: 5px 15px
      border-radius: 5px
      p
        margin: 0
        line-height: 1.4

  .addForm
    margin-top: 15px



.clearfix
  clear: both

.subtaskForm
  clear: both
  margin-top: 30px