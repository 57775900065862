.gridDelete
    color: darkred
    cursor: pointer
    transition: background-color 0.4s
    &:hover
        background-color: #cc9696 !important


.gridDeleteRowSelected
    >div
        background-color: rgba(209, 52, 56, 0.4) !important
