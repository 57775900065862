.filesContainer
  width: 100%
  display: flex
  flex-wrap: wrap
  padding: 10px 0

  .wConnectionsFieldEntry
    min-height: 28px
    margin: 3px 5px
    //padding: 3px 10px
    border-radius: 15px
    background-color: rgb(244, 244, 244)
    white-space: nowrap
    display: inline-flex

    .prepend, .delete
      width: 28px
      height: 28px
      text-align: center
      line-height: 28px
      border-radius: 15px
    .prepend
      background-color: lightgrey
      color: black
      font-size: 21px

    .content
      margin: 0 10px
      line-height: 28px
      text-overflow: ellipsis
      overflow: hidden

    .delete
      color: darkred
      cursor: pointer
      width: 38px
      background-color: #faebd7b3
      &:hover
        background-color: lightgrey


  a
    color: black
    text-decoration: none
  > div
    display: inline-flex
    max-width: 300px
    vertical-align: middle

    &, .prepend
      cursor: pointer
      color: black

    &:hover
      &, .prepend
        background-color: lightgrey

    .delete
      background-color: #f4f4f4
      color: black
      &:hover
        background-color: lightgrey