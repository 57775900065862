.main
    padding: 10px

    \:global
        .title
            color: white
            background-color: #444
        .w-command-bar
            border-bottom: none
            background-color: transparent !important
            a
                margin-top: -2px
                color: white
                border-bottom: 0 !important
                &:hover
                    background-color: #282828 !important
                    color: white !important

            svg
                color: white !important

.absencePanel
    width: 100%

.title
    font-weight: bold
    background-color: #DDDDDD
    padding: 3px 5px
    margin-bottom: 10px
    border-radius: 4px
