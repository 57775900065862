.main
    width: 600px
    padding: 20px

.nonEditableValues
    margin-bottom: 20px

.nonEditableValue
    margin-bottom: 10px

.offerLink
    font-size: 10px

.offerPin
    margin-top: 4px
    //font-size: 16px

.linkContainer
    margin-top: 30px
    margin-bottom: 25px
.copyIconContainer
    display: inline-block
    text-align: center
    border: solid 1px grey
    cursor: pointer
    //width: 150px
    padding: 5px 10px
    margin-bottom: 5px
    margin-right: 10px
    .copyIcon
        color: #005A9D
    &:hover
        background-color: #005A9D
        color: white
        .copyIcon
            color: white

.editButtons
    text-align: right
    margin-right: 10px
    margin-top: 20px
.editButton
    background-color: lightgrey
    color: black
    padding: 5px 20px
    display: inline-block
    margin-top: 5px
    margin-left: 10px
    &:hover
        background-color: #bdbdbd

.save
    background-color: #0078D7
    color: white
    &:hover
        background-color: #0061ad



