.iframeContainer
  height: calc( 100vh - 4px )
  width: calc( 100vw - 4px )
  z-index: 100000
  border: none
  .header
    position: relative
    background-color: white
    height: 20px
  .title
    height: 20px
    background-color: white
    font-size: 16px
    span
      padding-left: 10px
      padding-right: 10px
      background-color: rgba(30, 136, 229, 0.25)
      border: 1px solid rgb(30, 136, 229)
  .return
    padding-left: 10px
    padding-right: 10px
    position: absolute
    height: 20px
    top: 0
    right: 0
    background-color: rgba(30, 136, 229, 0.25)
    border: 1px solid rgb(30, 136, 229)
  .return:hover
    cursor: pointer
    background-color: rgba(30, 136, 229, 0.42)

  //float: right
  iframe
    display: block
    width: 100%
    height: 100%
    z-index: 100000
    background-color: white
    background-image: radial-gradient(rgba(255, 0, 0, 0.06), white)
    border: none
    //background-image: url(https://as-pl.com/resources/img/logo30.png)

