.editButtons
  margin-top: 10px
  text-align: right
  //margin-top: 20px
  .editButton
    background-color: lightgrey
    color: black
    padding: 5px 20px
    display: inline-block
    margin-top: 5px
    margin-left: 10px
    &:hover
      cursor: pointer
      background-color: grey
  .save
    background-color: #0078D7
    color: white
    &:hover
      background-color: #0061ad
      color: white
      cursor: pointer