.breadcrumbsContainer
  display: flex
  height: 45px
  .link
    &:hover
      cursor: pointer
      color: #0078D7
  .taskStatusContainer
    flex-grow: 1
    padding: 7px 7px 0 0
    .taskStatusInEdit
      background-color: white
      border-radius: 4px
      color: red
      margin-left: 20px
      padding: 6px 10px
      font-size: 13px
      border: 1px solid red
      float: right
    .taskStatusLocked
      border-radius: 4px
      color: white
      background-color: green
      margin-left: 20px
      padding: 6px 10px
      font-size: 13px
      float: right
      cursor: pointer
      &:hover
        background-color: darkgreen

.loadingIndicator
  padding-top: 150px
  font-size: 20px
  text-align: center

.maxPanelHeight
  max-height: 300px
  overflow: auto

.connectedProducts
  margin-top: 5px
  padding-inline-start: 20px !important
  line-height: 1.3
  a
    text-decoration: none
    color: #108ee9
    &:hover
      text-decoration: underline