.printTable
    tfoot
        display: none

    \:global
        .w-filter-openable
            display: none

.summary
  font-weight: bold
  font-size: 14px
  div
    background-color: #cccccc !important            