.backorder
  background-color: #e7e7e7
  border-radius: 3px
  padding: 5px
  margin-top: 5px
  border-top: solid 1px grey
  border-bottom: solid 1px grey
  font-size: 12px
  @media screen and (max-width: 540px)
    font-size: 10px



.row
  display: flex
  >span
    display: block

    &:nth-child(1)
      min-width: 35px
      margin-left: 5px
      text-align: left
      @media screen and (max-width: 1000px)
        margin-left: 0
        min-width: 20px
    &:nth-child(2)
      text-align: left

.date
  color: #5e5050

.thEqualWidth
  >th
    width: 20%

.backorderTable
  td, th
    vertical-align: middle !important

.backorderReady
  background-color: #00a854
  border-top: solid 1px green
  border-bottom: solid 1px green
  .date
    color: white