.main
    width: 500px
    //min-height: 500px
    padding: 20px

.nonEditableValues
    margin-bottom: 20px

.nonEditableValue
    margin-bottom: 10px

.editButtons
    text-align: right
    margin-right: 10px
    margin-top: 20px



.exit
    background-color: darkred
    color: white
    &:hover, &:focus
        background-color: #700101


.falconError
    border: 1px darkred solid
    background-color: #ffd4db
    color: darkred
    padding: 10px 10px
    margin-top: 18px
    margin-bottom: 18px


.w-switch > div:first-child .w-switch-label
    border-radius: 0
