.wComments
    textarea
        border-top: none
        border-right: none
        border-left: none
        resize: none
        &:focus
            outline: none
    .pullRight
        float: right
    .comment
        position: relative
        background: 0 0
        margin: .5em 0 0
        padding: .5em 0 0
        border: none
        border-top: none
        line-height: 1.2
        margin-bottom: 20px
        .avatar
            display: block
            width: 2.5em
            height: auto
            float: left
            margin: .2em 0 0
            img
                display: block
                margin: 0 auto
                width: 100%
                //height: 100%
                border-radius: .25rem
        .content
            margin-left: 3.5em
            display: block
            .metadata
                display: inline-block
                margin-left: .5em
                color: rgba(0, 0, 0, .4)
                font-size: .875em
                > *
                    display: inline-block
                    margin: 0 .5em 0 0

            .author
                font-size: 1em
                color: rgba(0, 0, 0, .87)
                font-weight: 700
            .text
                margin: .25em 0 .5em
                font-size: 1em
                word-wrap: break-word
                white-space: pre
                color: rgba(0, 0, 0, .87)
                line-height: 1.3
                overflow-x: auto            
            .actions
                font-size: .875em
                a
                    cursor: pointer
                    display: inline-block
                    margin: 0 .75em 0 0
                    color: rgba(0, 0, 0, .4)