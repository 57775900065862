.pageContainer
    background-color: #EEEEEE
    position: fixed
    width: 100%
    height: 100%
    overflow: hidden
    background-image: url("/next/login_background.webp")
    background-position: center
    background-size: cover


.logo
    position: absolute
    top: 15px
    left: 15px

.loginContainer


    top: calc(50% - 203px)
    left: calc(50% - 410px)

    position: absolute
    width: 820px
    height: 407px
    background-size: cover
    //border-radius: 5px
    //box-shadow: rgba(0, 0, 0, 0.5) 0px 5px 15px


.fieldContainer
    margin-top: 130px
    font-family: 'Roboto', sans-serif
    width: 410px
    border-radius: 5px

    background: rgba(0, 0, 0, .5)
    box-shadow: rgba(0, 0, 0, 0.5) 0px 5px 15px
    padding: 20px
    color: #ccc
    margin-left: auto
    margin-right: auto
    text-align: right
    input
        margin-bottom: 10px


.fieldError
    margin-bottom: 10px
    background-color: rgba(0,0,0, 0.5)
    border-radius: 2px
    padding: 2px
    margin-top: -10px
