.date
  color: gray
  position: absolute
  bottom: 0
  right: 0


.statusActive
  background-color: darkgreen !important

.statusTouched
  background-color: darkred !important
  svg
    vertical-align: -2px

.messageUnread
  background-color: darkgreen !important
  svg
    vertical-align: -2px

.requestsAmount
  background-color: #db731a !important
  svg
    vertical-align: -2px

.validUntil
  margin-top: 0.5px
  background-color: #969292 !important
  font-size: 11px
  svg
    font-size: 12px
    vertical-align: -2px

.row
  position: relative
  cursor: pointer
  min-height: 40px

.companyTitle
  font-weight: bold
  margin-bottom: 7px

.additionalInfo
  color: gray

.labels
  position: absolute
  right: 0
  top: 0
  min-width: 120px
.label
  float: right
  background-color: gray
  color: white
  padding: 2px 5px
  border-radius: 3px
  margin-left: 10px
