.loading
    padding: 40
    text-align: center

.smallPadding
    padding: 5 10 5 10

.buttonField
    margin-top: 27

.itRow
    div
    position: relative
    font-weight: bold

    &:before
        content: ''
        border: solid 1px lightgrey
        border-radius: 2px
        width: 40px
        height: 20px
        position: absolute
        right: 5px
        background: linear-gradient(to right, #009246 0, #009246 33%, #FFF 33%, #FFF 66%, #ce2b37 66%, #ce2b37 100%)
    