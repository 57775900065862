.dateColumn
    text-align: left
    position: relative

.flag
    position: relative
    &:before
        content: ''
        border: solid 1px lightgrey
        border-radius: 2px
        width: 35px
        height: 18px
        position: absolute
        right: 0
    div
        margin-right: 38px


.itRow
    &:before
        background: linear-gradient(to right, #009246 0, #009246 33%, #FFF 33%, #FFF 66%, #ce2b37 66%, #ce2b37 100%)

.esRow
    &:before
        background: linear-gradient(to bottom, #AA151B 0, #AA151B 30%, #F1BF00 30%, #F1BF00 70%, #AA151B 70%, #AA151B 100%)

.flagGB
    position: absolute    
    right: 0
    top: 0

.addBookmark    
    padding: 0 5 10 5
    cursor: pointer
    &:hover
        color: #c14141

.markInRealization        
    padding: 0 2 0 2
    &:hover
        cursor: pointer
        background-color: #90ee908c
